import React, { useEffect } from 'react'

import { triggerSubpubExport } from 'api/asset'

const UploadingView = () => {
  useEffect(() => {
    triggerSubpubExport().then().catch(err => {
      console.log(err)
    })
  }, [])
  return (
    <>
      <div className="bg-white min-h-full min-w-full flex flex-col lg:relative">
        <div className="flex-grow flex flex-col">
          <main className="flex-grow flex flex-col bg-white">
            <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 pt-10 sm:pt-16">
                <a href="/" className="inline-flex">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-12 w-auto inline-block"
                    src="/images/icon-logo.png"
                    alt="Exploration"
                  />
                </a>
              </div>
              <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                <p className="text-sm font-semibold text-red-600 uppercase tracking-wide">Export Subpublishing</p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Processing...
                </h1>
                <p className="mt-2 text-base text-gray-500">Once the file is processed, you'll receive a notification via email.</p>
              </div>
            </div>
          </main>
          <footer className="flex-shrink-0 bg-gray-50">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
              <div className="border-t py-8 text-sm text-gray-400 sm:text-left">
                <span className="block sm:inline">Copyright © {new Date().getFullYear()}  Exploration Group LLC.</span>{' '}
                <span className="block sm:inline">All rights reserved.</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

export default UploadingView
