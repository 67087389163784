import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Form } from 'react-bootstrap'

import InfoBar from 'components/InfoBar'
import ProgressBar from 'components/ProgressBar'
import Select from 'components/Select'
import FileInput from './FileInput'
import { transformData } from 'views/Upload/utils'

import { getFeedPresignedUrl, notifyFeedUploadComplete } from 'api/asset'

const monthOptions = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
]

const yearOptions = [
  { value: '2014', label: '2014' },
  { value: '2015', label: '2015' },
  { value: '2016', label: '2016' },
  { value: '2017', label: '2017' },
  { value: '2018', label: '2018' },
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' }
]

const reportOptions = [
  { value: 'youtube-pub', label: 'YouTube - Publishing' },
  { value: 'youtube-sr', label: 'YouTube - Sound Recording' },
  { value: 'youtube-film', label: 'YouTube - Film' },
  { value: 'subpublishing', label: 'SubPublishing' },
  { value: 'mlc', label: 'MLC' },
  { value: 'bmi', label: 'BMI' },
  { value: 'hfa', label: 'HFA' },
  { value: 'mri', label: 'MRI' },
  { value: 'record-distribution', label: 'Record Distribution' },
  { value: 'ascap', label: 'ASCAP' },
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'lyricfind', label: 'LyricFind' },
  { value: 'cmrra', label: 'CMRRA' },
  { value: 'sesac', label: 'SESAC' },
  { value: 'direct-licensing', label: 'Direct Licensing' }
]

const UploadForm = ({ datafeed, counter, setCounter }) => {
  const [saving, setSaving] = useState(false)
  const [saveFile, setSaveFile] = useState(null)
  const [reportType, setReportType] = useState('youtube-pub');
  const [percentage, setPercentage] = useState(0)
  const [progressText, setProgressText] = useState('Uploading your file...')
  const history = useHistory()

  let initialValues = {}

  useEffect(() => {
    if (datafeed) {
      let datafeedCopy = Object.assign({}, datafeed)
      initialValues = transformData(datafeedCopy)
    }
    reset(initialValues)
  }, [datafeed])

  const { register, handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const updateReportType = (newValue) => {
    setReportType(newValue);
  };

  async function uploadFile(formProps) {
    setSaving(true);
    // Prepare form values
    const formValues = {
      ...formProps,
      filename: saveFile.name,
      file_type: saveFile.type,
      report_type: reportType,
    };

    try {
      // Step 1: Get the presigned URL
      const presignedUrlResponse = await getFeedPresignedUrl(formValues);
      if (presignedUrlResponse.status !== 200) {
        throw new Error('Failed to get presigned URL');
      }

      const { presigned_post, feed_id } = presignedUrlResponse.data;

      // Step 2: Upload to S3 with Progress Tracking
      const formData = new FormData();
      Object.entries(presigned_post.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', saveFile);

      await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', presigned_post.url, true);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = Math.round((event.loaded / event.total) * 100);
            setPercentage(percentComplete);
          }
        };

        xhr.onload = function() {
          if (xhr.status === 204) { // 204 indicates a successful S3 upload
            resolve(); // Upload complete, resolve the promise
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };

        xhr.onerror = function() {
          reject(new Error('Upload failed due to a network error'));
        };

        xhr.send(formData);
      });

      setProgressText('Processing your file...')

      // Step 3: Notify backend of completion
      const notifyResponse = await notifyFeedUploadComplete({ feed_id });
      if (notifyResponse.status !== 200) {
        throw new Error('Failed to notify backend of upload completion');
      } else {
        toast.success('Your file has been uploaded.');
      }

      setPercentage(100);
      // history.push('/augmentor/dataset/');
      resetForm();

    } catch (err) {
      console.error('Error details:', {
        message: err.message,
        stack: err.stack,
        response: err.response ? {
          data: err.response.data,
          status: err.response.status,
          headers: err.response.headers
        } : 'No response'
      });
      setPercentage(100);
      setSaving(false);
      toast.error('An error occurred in processing your file.');
    }
  }

  const cleanForm = () => {
    reset({
      'month': '',
      'year': '',
      'notes': ''
    })
  }

  const resetForm = () => {
    setSaving(false)
    setSaveFile(null)
    setReportType('youtube-pub')
    setProgressText('Uploading your file...')
    reset()
  }

  return (<>
    {datafeed && <InfoBar
      text={`Editing information for ingest file: ${datafeed.filename}`}
      onClose={cleanForm}
    />}
    <Form className="divide-y divide-gray-200 bg-white shadow sm:rounded-md p-6" onSubmit={handleSubmit(uploadFile)}>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              {saving
                ? (<ProgressBar progressText={progressText} percentage={percentage} />)
                : (<Controller
                  control={control}
                  name={`file`}
                  render={() => (
                    <FileInput
                      file={saveFile}
                      setFile={setSaveFile}
                      updateReportType={updateReportType}
                      reportOptions={reportOptions}
                    />
                  )}
                />)
              }
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">File Information</h3>
          </div>
          <div className="my-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="report_type" className="block text-sm font-medium text-gray-700">
                Report Type
              </label>
              <div className="mt-1">
                <Select
                  id="report_type"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="report_type"
                  options={reportOptions}
                  register={register}
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="month" className="block text-sm font-medium text-gray-700">
                Month
              </label>
              <div className="mt-1">
                <Select
                  id="month"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="month"
                  options={monthOptions}
                  register={register}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="Year" className="block text-sm font-medium text-gray-700">
                Year
              </label>
              <div className="mt-1">
                <Select
                  id="year"
                  className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  name="year"
                  options={yearOptions}
                  register={register}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => resetForm()}
            disabled={saving}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={saving}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </Form>
  </>)
}

export default (UploadForm)
