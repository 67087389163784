import React from 'react'
import Paginator from 'components/Paginator'
import { Menu } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/outline'

const statuses = {
  'processed': 'text-green-700 bg-green-50 ring-green-600',
  'processing': 'text-yellow-700 bg-yellow-50 ring-yellow-600',
  'invalid': 'text-red-800 bg-red-50 ring-red-600',
}

function capitalizeFirstLetter(string) {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const StackedTable = ({ data, getNext, getPrev, currPage, setCurrPage, hasNext, pages, showPagination }) => {
  let _data = data || []

  return (
    <div className="flex flex-col px-5 py-5">
      <ul role="list" className="divide-y divide-gray-100">
        {_data.map((item) => (
          <li key={item.id} className="flex items-start justify-between gap-x-6 py-5">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold text-gray-900">{item.filename}</p>
                <p className={`mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${statuses[item.status]}`}>
                  {item.status === 'processing' ? `Processing (${item.percentage}%)` : capitalizeFirstLetter(item.status)}
                </p>
              </div>
              <div className="mt-1 text-xs text-gray-500 space-y-1">
                <p className="whitespace-nowrap">
                  Uploaded: {new Date(item.created).toLocaleString()}
                </p>
                <p className="whitespace-nowrap">
                  Completed: {new Date(item.date_of_completion).toLocaleString()}
                </p>
                <p className="truncate">
                  Uploaded by: {item.created_by}
                </p>
              </div>
            </div>
            <div className="flex flex-none justify-end">
              <div className='flex flex-grow-0  gap-x-4 content-start'>
                {['processed', 'valid'].includes(item.status) &&
                  <a
                    href={item.output_link}
                    className="hidden rounded-md bg-red-600 hover:bg-red-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm sm:block"
                    target='_blank'
                    rel='noreferrer'
                  >
                    Download
                  </a>
                }
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon aria-hidden="true" className="h-5 w-5" />
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      <a
                        href={`/augmentor/stats/${item.pk}/`}
                        className="block px-3 py-1 text-sm text-gray-900 hover:bg-gray-50"
                      >
                        Review matches
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        href={item.output_link + "?music_admin=yes"}
                        className="block px-3 py-1 text-sm text-gray-900 hover:bg-gray-50"
                      >
                        Music Admin
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        href={item.output_link + "?music_admin=no"}
                        className="block px-3 py-1 text-sm text-gray-900 hover:bg-gray-50"
                      >
                        Exploration
                      </a>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {showPagination && (
        <Paginator
          getNext={getNext}
          getPrev={getPrev}
          currPage={currPage}
          setCurrPage={setCurrPage}
          hasNext={hasNext}
          totalPages={pages}
        />
      )}
    </div>
  )
}

export default StackedTable
